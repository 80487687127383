import proxyService from '../../proxy/proxy.service';


export const profileService = {
  getUserInfo
}

async function getUserInfo() {
  const url = '/api/identity/my-profile';

  const result = await proxyService.get(url);

  return result;
};

export default profileService;