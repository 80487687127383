const actionsTypes = {
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
};

const initialState = {
    showModal: false,
    message: '',
};
const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.openModal:
            return {
                ...state,
                showModal: true,
                message: action.message,
            };
        case actionsTypes.closeModal:
            return {
                ...state,
                showModal: false,
                message: '',
            };
        default:
            return {
                ...state,
            };
    }
};
export default modalReducer;