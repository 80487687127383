// import { cacheService } from '../utilities/services/cache/cache.service';
import { GET_USER_INFO, GET_USER_INFO_FAILED, LOGIN_FAILED, LOGIN_SUCCESS } from '../constants/index';

// const currentUser = cacheService.get(LOCAL_STORAGE_USER_INFO);
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GET_USER_INFO:
      return {
        ...state,
        ...action.userInfo
      }
    case GET_USER_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default userReducer;