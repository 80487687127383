const paginationOptions = (
  { page, prePage, nextPage,
    firstPage, lastPage, className,
    noDataText }) => {
  return {
    page: page || 1,
    prePage: prePage || '<',
    nextPage: nextPage || '>',
    firstPage: firstPage || 'Trang đầu',
    lastPage: lastPage || 'Trang cuối',
    className: className || 'pagination-table',
    noDataText: noDataText || 'Không tìm thấy dữ liệu'
  }
}

const normalizeString = (str) => {
  if (!str) { return '' };
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .toLowerCase()
    .trim();
}

const generateIV = () => (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 16);

export default  { paginationOptions, normalizeString, generateIV };
