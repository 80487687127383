import { POST_SHOW_ITEM } from "../../constants/showItems/showItems";
const initialShowItems = {
    total: 12,
};
const showItemsReducers = (state = initialShowItems, action) => {
    switch (action.type) {
        case POST_SHOW_ITEM:
            const total = action.total;
            return {
                ...state,
                total,
            };
        default:
            return state;
    }
};

export default showItemsReducers;
