import { lazy } from 'react';

const DashBoard = lazy(() => import('../../pages/users/home/DashBoard'));
const Agricultural = lazy(() => import('../../pages/users/agricultural/Agricultural'));
const AquaticProducts = lazy(() => import('../../pages/users/aquatic-products/AquaticProducts'));
const ForestProducts = lazy(() => import('../../pages/users/forest-products/ForestProducts'));
const Seafood = lazy(() => import('../../pages/users/seafood/Seafood'));

export const CONFIGROUTES = {
    'home': DashBoard,
    'DashBoard': DashBoard,
    'Agricultural': Agricultural,
    'AquaticProducts': AquaticProducts,
    'ForestProducts': ForestProducts,
    'Seafood': Seafood

};


export const GET_ROUTES_SUCCESS = 'GET_ROUTES_SUCCESS';
export const GET_ROUTES_LOADING = 'GET_ROUTES_LOADING';
export const GET_ROUTES_ERROR = 'GET_ROUTES_ERROR';
