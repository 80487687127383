// import axios from 'axios';
import proxyService from '../proxy/proxy.service';
import cacheService from '../cache/cache.service';
import { rootMenu, extraMenu } from '../../../assets/datas/root-menu';
import { LOCAL_STORAGE_USER_INFO, ROOT } from '../../../constants/index';
export const routeService = { getMenuLeft };

async function getMenuLeft() {
  const userRole = cacheService.get(LOCAL_STORAGE_USER_INFO)?.role;
  const url = userRole === ROOT ? '/api/app/menuManagement/menuData' : '/api/app/menuManagement/userMenu?menuLocation=1';
  let menuList = [];
  let menus = [];
  let mapped = [];
  return await proxyService.get(url).then(result => {
    switch (userRole) {
      case ROOT:
        menus = result.data.menuTableField.filter(x => x.menuLocation === 1 && x.activation);
        break;
      default:
        menus = result.data.filter(x => x.activation);
        break;
    }
    mapped = routeMap(menus);
    menuList = mapped;
    menuList = menuList.reduce((accumulator, current) => {
      if (!accumulator.some(x => x.code === current.code)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    const childMenu = mapped.filter(x => x.parentMenuId)
    if (childMenu.length) {
      childMenu.forEach(item => {
        const parentMenu = menuList.find(x => x.id === item.parentMenuId);
        if (!parentMenu) return;
        parentMenu.children.push(item);
        menuList = menuList.filter(x => x.id !== item.id);
      })
    }
    menuList = menuList.filter(x => !x.parentMenuId);
    return menuList;
  });
};

function routeMap(obj) {
  // const flatObj = [...extraMenu, ...extraMenu.filter(x => x.children && x.children.length).flatMap(x => { return x.children })];
  return obj && obj.map((x, index) => {
    // const menuRootItem = flatObj.find(m => m.code === x.menuCode);
    const menuItem = extraMenu.find(e => e.url == x.url);
    // extraMenuItem.icon = x.iconPath || extraMenuItem.icon;
    // const menuItem = menuRootItem || extraMenuItem;

    return {
      id: x.id,
      code: x.menuCode,
      collapse: x.collapse || false,
      name: x.menuName,
      url: x.url,
      icon: x.symbol ? x.symbol : menuItem && menuItem.icon,
      children: x.childMenu && x.childMenu.length ? routeMap(x.childMenu) : menuItem && menuItem.children || [],
      component: x.childMenu && x.childMenu.length ? '' : menuItem && menuItem.component,
      state: menuItem.state,
      parentMenuId: x.parentMenuId
    }
  });
}
export default routeService;