export const GET_MENU = '';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED  = 'LOGIN_FAILED';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';

export const LOCAL_STORAGE_TOKEN = 'token';
export const LOCAL_STORAGE_USER_INFO = 'user_info';
export const ROOT = 'root';

export const AES_KEY = 'C0nt3ntManag3m3ntSyst3m_hgt!16kl';
export const DATATABLE_MODAL_CREATE_MODE = 'DATATABLE_MODAL_CREATE_MODE';
export const DATATABLE_MODAL_UPDATE_MODE = 'DATATABLE_MODAL_UPDATE_MODE';
