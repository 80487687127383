import socketIOClient from "socket.io-client";
// const URL_SOCKET = "http://172.29.14.74:3001";
var socket;
const connect = (callback) => {
    socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        // transports: ["websocket"],
        jsonp: false,
        allowUpgrades: false,
        // pingTimeout: 30000,
    });

    socket.on("connect", function (data) {
        console.log("connect");
        callback();
    });

    socket.on("disconnect", function () {
        console.log("disconnect");
        socket.off("new-problem");
    });
};

const onNewProblem = (callback) =>
    socket.on("new-problem", function (data) {
        callback(data);
    });
const disconnect = () => socket.disconnect();
export const SocketHelper = { connect, onNewProblem, disconnect };
