import {
  GET_PROBLEM_SUCCESS,
  GET_PROBLEM_ERROR,
  ADD_NEW_PROBLEM,
  ADD_LIST_PROBLEM_FROM_SERVER,
} from "../../constants/problems/problems";

const initialProblems = {
  data: [],
};
const problemReducers = (state = initialProblems, action) => {
  switch (action.type) {
    case ADD_LIST_PROBLEM_FROM_SERVER:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };

    case GET_PROBLEM_ERROR:
      return {
        ...state,
        error: action.payload,
        GET_PROBLEM_ERROR: [],
      };

    case ADD_NEW_PROBLEM:
      console.log("action", action);
      return {
        ...state,
        data: [action.payload, ...state.data],
        newProblem: action.payload,
      };

    default:
      return state;
  }
};

export default problemReducers;
