import { GET_ANOUNCEMENT_SUCCESS, GET_ANOUNCEMENT_ERROR } from "../../constants/anouncements/anouncements";

const anouncementReducers = (state = [], action) => {
    switch (action.type) {
        case GET_ANOUNCEMENT_SUCCESS:
            const anouncements = action.anouncements;
            return {
                ...state,
                anouncements
            };
        case GET_ANOUNCEMENT_ERROR:
            return {
                ...state,
                error: action.payload,
                GET_ANOUNCEMENT_ERROR: []
            }
        default:
            return state;
    }
};

export default anouncementReducers;

export const getAnouncementsSuccess = state => state.users
export const getAnouncementsError = state => state.error;