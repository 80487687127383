import { GET_USER_INFO, GET_USER_INFO_FAILED } from "../constants"

export const profileAction = {
    getUserInfo,
    getUserInfoFailed
}

function getUserInfo(userInfo) {
    return {
        type: GET_USER_INFO,
        userInfo
    }
}

function getUserInfoFailed(error) {
    return {
        type: GET_USER_INFO_FAILED,
        payload: error
    };
}