import React, { useEffect } from "react";
import "./App.css";
import { Router, Switch, Route } from "react-router-dom";

import indexRoutes from "./commons/routes/index";
import { PrivateRoute } from "./commons/routes/private-routes";

import LoginPage from "./pages/login/Login";
import { configureStore } from "./store/store";
import { Provider } from "react-redux";
import { history } from "./utilities/helpers/history";
import Loading from "./commons/components/loading/Loading";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { SocketHelper } from "./utilities/helpers/socket-helper";
import { ADD_NEW_PROBLEM } from "./constants/problems/problems";
const store = configureStore();
const App = () => {
  useEffect(() => {
    SocketHelper.connect(() => {
      // console.log("ss conec");
      SocketHelper.onNewProblem((data) => {
        console.log("data", data);
        data.isNew = true;
        store.dispatch({ type: ADD_NEW_PROBLEM, payload: data });
        NotificationManager.warning(
          data.name ?? "",
          "Thông báo sự cố",
          2500,
          () => {}
        );
      });
    });
    return () => {
      SocketHelper.disconnect()
    };
  }, []);
  return (
    <Provider store={store}>
      <Router history={history}>
        <Loading />
        <Switch>
          <Route path="/login" component={LoginPage} />;
          {indexRoutes.map((prop, key) => {
            return (
              <PrivateRoute
                path={prop.path}
                key={key}
                component={prop.component}
              />
            );
          })}
        </Switch>
      </Router>
      <NotificationContainer />
    </Provider>
  );
};

export default App;
