import React, { useState } from "react";
import { useDispatch } from "react-redux";
import backgroundImg from "../../assets/images/login/bg-login.jpg";
import logoLoginImg from "../../assets/images/login/logoLogin.png";
import { history } from "../../utilities/helpers/index";
import { loginActions } from "../../actions/login-actions";
import tokenService from "../../utilities/services/token/token.service";
import authenticateService from '../../utilities/services/authenticate/authenticate.service';
import { cacheService } from "../../utilities/services";
import { LOCAL_STORAGE_USER_INFO } from "../../constants/index";
import loadingAction from "../../actions/loading/loading.action";
export class LoginResponse {
    accessToken;
    encryptedAccessToken;
    expireInSeconds;
    userId;
}
const Login = () => {
    const [inputs, setInputs] = useState({
        username: "",
        password: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const [invalidPass, setInvalidPass] = useState(false);
    const { username, password } = inputs;
    const dispatch = useDispatch();
    const sidebarBackground = {
        backgroundImage: "url(" + backgroundImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
    };

    const inputStyle = {
        borderRadius: "0 .35rem .35rem 0",
    };

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (username && password) {
            authenticateService.authenticate(username, password)
                .then(result => {
                    tokenService.storeToken(result.accessToken);
                    const tokenDecode = decodeURIComponent(escape(atob(result.accessToken.split('.')[1])));
                    dispatch(loginActions.login(tokenDecode));
                    cacheService.set(LOCAL_STORAGE_USER_INFO, tokenDecode)
                    history.push('/home');
                })
                .catch(err => {
                    dispatch(loginActions.loginFailed(err));
                    dispatch(loadingAction.close());
                    setInvalidPass(true);
                });
        }
    }
    return (
        <div className="" style={sidebarBackground}>
            <div className="login-header">
            </div>
            <div className="login-content">
                <div className="login-content-body">
                    <img src={logoLoginImg} alt="" className="" />
                    <div className="">
                        <p className="login-title">Nhập thông tin đăng nhập</p>
                        <form name="form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className="login-group-input">
                                    <i className="login-icon-user ti-user"></i>
                                    <input
                                        placeholder="Tên Đăng nhập"
                                        style={inputStyle}
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={handleChange}
                                        className={"form-control" + (submitted && !username ? " is-invalid" : "")}
                                    />
                                </div>
                                <div className="login-error">{submitted && !username && <div>Tên đăng nhập là bất buộc</div>}</div>
                            </div>
                            <div className="form-group">
                                <div className="login-group-input">
                                    <i className="login-icon-user ti-pencil"></i>
                                    <input
                                        placeholder="Mật khẩu"
                                        style={inputStyle}
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                        className={"form-control" + (submitted && !password ? " is-invalid" : "")}
                                    />
                                </div>
                                <div className="login-error">
                                    {submitted && !password && <div>Mật khẩu là bất buộc</div>}
                                    {submitted && invalidPass && <div>Tên đăng nhập hoặc mật khẩu không đúng</div>}
                                </div>
                            </div>
                            <div className="login-group-btn form-group">
                                <button className="btn-login btn btn-primary">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="login-footer">
            </div>
        </div>
    );
};

export default Login;
