import { LOGIN_FAILED, LOGIN_SUCCESS } from "../constants";

function login(user) {
    return {
        type: LOGIN_SUCCESS,
        user
    }
}

function loginFailed(error) {
    return {
        type: LOGIN_FAILED,
        payload: error
    };
}

export const loginActions = {
    login,
    loginFailed
}