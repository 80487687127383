import { combineReducers } from "redux";
import settings from "./settings/settingsReducers";
import user from "./userReducer";
import menuReducers from "./routes/routeReducers";
import formFieldReducers from "./form-field/formFieldReducer";
import modalReducer from "./modal/modalReducer";
import loadingReducer from "./loading/loadingReducer";
import anouncementReducer from "./anouncements/anouncementReducers";
import menuManagementReducers from "./menu-management/menuManagementReducer";
import problemReducers from "./problem/problemReducers";
import showItemsReducers from "./showItems/showItemsReducers";

const rootReducers = combineReducers({
    settings,
    user,
    menuReducers,
    loadingReducer,
    formFieldReducers,
    modalReducer,
    anouncementReducer,
    menuManagementReducers,
    problemReducers,
    showItemsReducers,
});

export default rootReducers;
