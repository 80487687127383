import { lazy } from "react";
const DashBoard = lazy(() => import("../../pages/users/home/DashBoard"));
const Menu = lazy(() => import("../../pages/users/system-management/menu/Menu"));
const AccountManagement = lazy(() => import("../../pages/users/user-info/AccountManagement"));
const UserRole = lazy(() => import("../../pages/users/user-info/UserRole"));
const MobileMenu = lazy(() => import("../../pages/users/additional-menu/MobileMenu"));
const SystemMenu = lazy(() => import("../../pages/users/additional-menu/SystemMenu"));
const WebsiteMenu = lazy(() => import("../../pages/users/additional-menu/WebsiteMenu"));

const WebsiteMonitoring = lazy(() => import("../../pages/users/website-monitoring/WebsiteMonitoring"));
const WebServerMonitoring = lazy(() => import("../../pages/users/web-server-monitoring/WebServerMonitoring"));
const MailServerMonitoring = lazy(() => import("../../pages/users/mail-server-monitoring/MailServerMonitoring"));
const OtherMonitoring = lazy(() => import("../../pages/users/other-monitoring/OtherMonitoring"));
const Configuration = lazy(() => import("../../pages/users/configuration/Configuration"));
const ServerMonitoring = lazy(() => import("../../pages/users/server-monitoring/ServerMonitoring"));
const NetworkDeviceMonitoring = lazy(() => import("../../pages/users/network-device-monitoring/NetworkDeviceMonitoring"));
const Ping = lazy(() => import("../../pages/users/ping/Ping"));

export const rootMenu = [

];

export const extraMenu = [
    {
        url: "/mobileMenu",
        name: "Mobile Menu",
        code: "mobileMenu",
        icon: "fas fa-mobile-alt",
        component: MobileMenu,
        state: "mobileMenu",
        availableForAdmin: false,
    },
    {
        url: "/systemMenu",
        name: "System Menu",
        code: "systemMenu",
        icon: "fas fa-laptop",
        component: SystemMenu,
        state: "systemMenu",
        availableForAdmin: false,
    },
    {
        url: "/websiteMenu",
        name: "Website Menu",
        code: "websiteMenu",
        icon: "fab fa-chrome",
        component: WebsiteMenu,
        state: "websiteMenu",
        availableForAdmin: false,
    },
    {
        url: "/home",
        name: "dashBoardMenu",
        code: "dashBoardMenu",
        icon: "fas fa-tachometer-alt",
        component: DashBoard,
        state: "home",
        availableForAdmin: false,
        redirect: true
    },
    {
        url: "/server-monitoring",
        name: "serverMonitoring",
        code: "serverMonitoring",
        icon: "fas fa-server",
        component: ServerMonitoring,
        state: "serverMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/network-device-monitoring",
        name: "networkDeviceMonitoring",
        code: "networkDeviceMonitoring",
        icon: "fas fa-network-wired",
        component: NetworkDeviceMonitoring,
        state: "networkDeviceMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/website-monitoring",
        name: "websiteMonitoring",
        code: "websiteMonitoring",
        icon: "far fa-file-word",
        component: WebsiteMonitoring,
        state: "WebsiteMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/web-server-monitoring",
        name: "webServerMonitoring",
        code: "webServerMonitoring",
        icon: "fas fa-globe",
        component: WebServerMonitoring,
        state: "webServerMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/mail-server-monitoring",
        name: "mailServerMonitoring",
        code: "mailServerMonitoring",
        icon: "fas fa-envelope",
        component: MailServerMonitoring,
        state: "mailServerMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/other-monitoring",
        name: "otherMonitoring",
        code: "otherMonitoring",
        icon: "fas fa-project-diagram",
        component: OtherMonitoring,
        state: "otherMonitoring",
        availableForAdmin: false,
    },
    {
        url: "/thietlap",
        name: "configuration",
        code: "configuration",
        icon: "fas fa-tools",
        component: Configuration,
        state: "configuration",
        availableForAdmin: false,
    },
    {
        collapse: true,
        name: "userInfo",
        code: "thongtinnguoidung1",
        icon: "fas fa-users",
        component: "",
        state: "users",
        url: "/userInfo",
        availableForAdmin: false,
    },
    {
        url: "/userInfo/roles",
        name: "userRole",
        code: "userRole",
        mini: "B",
        icon: "fa fa-unlock",
        component: UserRole,
        availableForAdmin: false,
    },
    {
        url: "/userInfo/account",
        name: "accountManagement",
        code: "accountManagement",
        mini: "C",
        icon: "fa fa-user",
        component: AccountManagement,
        availableForAdmin: false,
    },
    {
        collapse: true,
        name: "systemManagement",
        code: "quantrihethong",
        icon: "fas fa-cogs",
        component: "",
        state: "systemManagement",
        url: "/menuManagement",
        availableForAdmin: false,
    },
    {
        url: "/menuManagement/menu",
        name: "menuManagement",
        code: "menuManagement",
        mini: "A",
        icon: "fas fa-plus",
        component: Menu,
        state: "systemManagement",
        availableForAdmin: false,
    },
    {
        url: "/ping",
        name: "Ping",
        code: "Ping",
        mini: "A",
        icon: "fa fa-terminal",
        component: Ping,
        state: "systemManagement",
        availableForAdmin: false,
    },
];
export default { rootMenu, extraMenu };
