const INIT_STATE = {};

const menuManagementReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_PARENT_MENU':
      return {
        ...state
      };
    case 'SAVE_PARENT_MENU':
      const parentMenuList = action.payload
      const a = {
        ...state,
        parentMenuList
      }
      return a
    default:
      return state;
  }
};

export default menuManagementReducers;